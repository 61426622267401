import { FC, ComponentProps, useCallback } from 'react'
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'

import { AlertDialog } from '@/components/ui/alertDialog/AlertDialog'
import { Button } from '@/components/ui/button/Button'
import { RadioGroup } from '@/components/ui/radio-group/RadioGroup'
import {
  TaskThreadEvent,
  TaskThreadEventPayloadFileMetadata,
  TaskThreadEventPayloadImageFileMetadata,
} from '@/features/task/components/chat/threadPayloadTypes'
import { useCreateChatMessage } from '@/features/task/components/chat/useCreateChatMessage'
import { useCreateTask } from '@/features/task/hooks/useCreateTask'
import { useTask } from '@/features/task/hooks/useTask'
import { useHandleOpenStack } from '@/lib/hooks/useHandleOpenStack'
import { logger } from '@/lib/logger'
import { parseError } from '@/lib/utilities'

interface NewTaskFromPostDialogProperties
  extends Pick<
    ComponentProps<typeof AlertDialog>,
    'isDialogOpen' | 'onOpenChange'
  > {
  taskId: string
  threadEvent: TaskThreadEvent
}

const createTaskOptions = [
  { label: 'Create task here', value: 'here' },
  { label: 'Create task elsewhere', value: 'elsewhere' },
] as const satisfies ComponentProps<typeof RadioGroup>['values']

interface NewTask {
  title: string
  target: (typeof createTaskOptions)[number]['value']
}

const title = <span className="text-2xl font-medium">New task from post</span>

export const NewTaskFromPostDialog: FC<NewTaskFromPostDialogProperties> = ({
  isDialogOpen,
  onOpenChange,
  taskId,
  threadEvent,
}) => {
  const methods = useForm<NewTask>({
    defaultValues: { target: 'here', title: 'New Task' },
  })
  const { handleSubmit, register, reset } = methods
  const { task } = useTask(taskId)
  const { isPending: createTaskIsPending, mutateAsync: createTask } =
    useCreateTask({ skipOnError: true, taskId })
  const { handleAddToStack, handleOpenStack } = useHandleOpenStack('stack')
  const { isPending: createMessageIsPending, mutateAsync: createNewMessage } =
    useCreateChatMessage({})

  const closeDialog = useCallback(() => {
    reset()
    onOpenChange?.(false)
  }, [onOpenChange, reset])

  const isTaskCreationPending = createTaskIsPending || createMessageIsPending

  const handleTaskCreation: SubmitHandler<NewTask> = useCallback(
    async ({ target, title }) => {
      try {
        const {
          id: createdTaskId,
          thread: { id: createdThreadId },
        } = await createTask({
          parentTaskId: target === 'here' ? taskId : undefined,
          title,
        })

        const message = threadEvent.payload.find(
          (payload) => payload.__typename === 'Message'
        )

        const fileIds = threadEvent.payload
          .filter(
            (
              payload
            ): payload is
              | TaskThreadEventPayloadFileMetadata
              | TaskThreadEventPayloadImageFileMetadata =>
              ['FileMetadata', 'ImageFileMetadata'].includes(payload.__typename)
          )
          .map((file) => file.id)

        const text = `**${message?.author.name ?? 'Unknown user'}** posted in “${task.title}”
    > ${message?.body.text ?? ''}`

        closeDialog()

        target === 'here'
          ? handleAddToStack(createdTaskId)
          : handleOpenStack(createdTaskId)

        await createNewMessage({
          body: { fileIds, text: text ?? '' },
          threadId: createdThreadId,
        })
      } catch (error) {
        logger.error(error)
        toast.error(parseError(error, 'Failed to create task'))
      }
    },
    [
      closeDialog,
      createNewMessage,
      createTask,
      handleAddToStack,
      handleOpenStack,
      task.title,
      taskId,
      threadEvent.payload,
    ]
  )

  const dialogContent = useCallback(
    ({
      actionButtonReference,
      Description,
      handleSubmitKeyDown,
    }: ComponentProps<
      NonNullable<ComponentProps<typeof AlertDialog>['content']>
    >) => (
      <FormProvider {...methods}>
        <form
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(handleTaskCreation)}
          onKeyDown={handleSubmitKeyDown}
        >
          <Description asChild>
            <div className="flex flex-col gap-3 pt-5">
              <RadioGroup
                values={createTaskOptions}
                name={register('target').name}
                className="flex flex-col gap-3"
              />
            </div>
          </Description>
          <div className="mt-3 flex items-start justify-end gap-2 rounded-xl border border-film-normal bg-mono-paper p-2">
            <Button
              type="reset"
              disabled={isTaskCreationPending}
              onClick={closeDialog}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="solid"
              ref={actionButtonReference}
              disabled={isTaskCreationPending}
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    ),
    [
      methods,
      handleSubmit,
      handleTaskCreation,
      closeDialog,
      register,
      isTaskCreationPending,
    ]
  )

  return (
    <AlertDialog
      className="bg-paper-high"
      isDialogOpen={isDialogOpen}
      onOpenChange={onOpenChange}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onActionClick={handleSubmit(handleTaskCreation)}
      content={dialogContent}
      title={title}
      cancelText="Cancel"
    />
  )
}

NewTaskFromPostDialog.displayName = 'NewTaskFromPostDialog'
