import { useCallback, useEffect, useMemo } from 'react'

import {
  subtaskListSelectorsFactory,
  subtasksReducer,
} from '@/features/task/components/subtasks/reducer'
import { SubtasksListItem } from '@/features/task/components/subtasks/SubtasksListItem'
import { useDeleteTask } from '@/features/task/hooks/useDeleteTask'
import { useMoveTask } from '@/features/task/hooks/useMoveTask'
import { useOrderSubtasks } from '@/features/task/hooks/useOrderSubtasks'
import { TaskData } from '@/features/task/hooks/useTask'
import { useHighlightableList } from '@/lib/hooks/use-highlightable-list/useHighlightableList'
import { isCtrlPressed } from '@/lib/utilities'
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useSearch } from '@tanstack/react-router'

type SubtaskData = TaskData['subtasks'][number]

interface SubtasksListProperties {
  updateSubtaskTitle: (title: string, id: string) => void
  subtasks: SubtaskData[]
  taskId: string
}

export const SubtasksList = ({
  subtasks,
  taskId,
  updateSubtaskTitle,
}: SubtasksListProperties) => {
  const { stack } = useSearch({
    from: '/_private',
  })

  const { dispatch, state } = useHighlightableList({
    initialState: {
      highlightCursor: undefined,
      highlightRange: 0,
      isContextMenuOpen: false,
      isDeleteDialogOpen: false,
      isRenamingSubtask: false,
      items: subtasks.map((subtask) => subtask.id),
      subtasks,
    },
    listItems: subtasks,
    reducer: subtasksReducer,
  })

  useEffect(() => {
    const currentTaskIndex = stack?.indexOf(taskId) ?? -1
    const openedChildTaskId = stack?.[currentTaskIndex + 1]

    if (!openedChildTaskId) return

    dispatch({
      payload: openedChildTaskId,
      type: 'onRealFocusChange',
    })
  }, [dispatch, stack, subtasks, taskId])

  const { highlightCursor, highlightRange } = state
  const selectors = subtaskListSelectorsFactory(state)
  const highlightStartIndex = selectors.getHighlightStartIndex()

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  )

  const deleteTaskMutation = useDeleteTask({ taskId })
  const orderSubtasksMutation = useOrderSubtasks(taskId)
  const { moveTask } = useMoveTask(taskId)

  const handleDelete = useCallback(
    (ids: string[]) => {
      for (const id of ids) {
        deleteTaskMutation.mutate(id)
      }
    },
    [deleteTaskMutation]
  )

  const moveItemToIndex = useCallback(
    (activeIndex: number, overIndex: number) => {
      const active = subtasks[activeIndex]
      const over = subtasks[overIndex]

      if (!active || !over) {
        return
      }

      const insertAfterSubtaskWithId =
        activeIndex < overIndex
          ? over.id
          : overIndex - 1 >= 0
            ? subtasks[overIndex - 1]?.id
            : undefined

      orderSubtasksMutation.mutate({
        activeId: active.id,
        insertAfterSubtaskWithId,
        orderedSubtaskIds: [active.id],
        overId: over.id,
      })
    },
    [orderSubtasksMutation, subtasks]
  )

  const handleMove = useCallback(
    (id: string) => {
      void moveTask(
        {
          ...state.copiedTask,
          id,
          parentTaskId: taskId,
        },
        highlightCursor ?? ''
      )
      dispatch({ payload: id, type: 'onSubtaskMoved' })
    },
    [moveTask, state.copiedTask, taskId, highlightCursor, dispatch]
  )

  const handleArrowUpDown = useCallback(
    (event: KeyboardEvent) => {
      if (isCtrlPressed(event) && highlightRange !== 0) {
        // For now we don't support reordering with multiple selected items
        return true
      }

      if (event.key === 'ArrowUp' && isCtrlPressed(event)) {
        if (event.shiftKey) {
          moveItemToIndex(highlightStartIndex, 0)
        } else if (highlightStartIndex > 0) {
          moveItemToIndex(highlightStartIndex, highlightStartIndex - 1)
        }
        return true
      }

      if (event.key === 'ArrowDown' && isCtrlPressed(event)) {
        if (event.shiftKey) {
          moveItemToIndex(highlightStartIndex, subtasks.length - 1)
        } else if (highlightStartIndex < subtasks.length - 1) {
          moveItemToIndex(highlightStartIndex, highlightStartIndex + 1)
        }
        return true
      }

      return false
    },
    [moveItemToIndex, highlightRange, highlightStartIndex, subtasks.length]
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (over && active.id !== over.id) {
        const activeIndex = subtasks.findIndex(
          (subtask) => subtask.id === active.id
        )
        const overIndex = subtasks.findIndex(
          (subtask) => subtask.id === over.id
        )
        moveItemToIndex(activeIndex, overIndex)
      }
    },
    [moveItemToIndex, subtasks]
  )

  const dndModifiers = useMemo(() => [restrictToVerticalAxis], [])

  return (
    <ul
      className="overflow-hidden rounded-lg border border-film-subtle shadow-sm focus:outline-hidden"
      data-testid="subtaskList"
      aria-multiselectable="true"
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={dndModifiers}
      >
        <SortableContext
          items={subtasks}
          strategy={verticalListSortingStrategy}
        >
          {subtasks.map((subtask, index) => {
            return (
              <SubtasksListItem
                task={subtask}
                index={index}
                key={subtask.id}
                subtasksLength={subtasks.length}
                state={state}
                dispatch={dispatch}
                onMove={handleMove}
                onDelete={handleDelete}
                onUpdateSubtaskTitle={updateSubtaskTitle}
                handleArrowUpDown={handleArrowUpDown}
                parentId={taskId}
              />
            )
          })}
        </SortableContext>
      </DndContext>
    </ul>
  )
}

SubtasksList.displayName = 'SubtasksList'
