import debounce from 'lodash/debounce'
import uniq from 'lodash/uniq'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { useChatOverlay } from '@/features/task/components/ChatOverlayProvider'
import { SubtasksContainer } from '@/features/task/components/subtasks/SubtasksContainer'
import { SubtasksPlanning } from '@/features/task/components/subtasks/SubtasksPlanning'
import { SubtasksPlanningLoadingView } from '@/features/task/components/subtasks/SubtasksPlanningLoadingView'
import { SubtasksPlanningPrecedentSelection } from '@/features/task/components/subtasks/SubtasksPlanningPrecedentSelection'
import { SubtasksRefinementError } from '@/features/task/components/subtasks/SubtasksRefinementError'
import { useRefineTaskMutation } from '@/features/task/hooks/useRefineTaskMutation'
import { TaskData } from '@/features/task/hooks/useTask'
import { UNGROUNDED_TASK_ERROR } from '@/features/task/utils/constants'
import { CreateTaskInput, RefineTaskMutation } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'

type SubtaskData = TaskData['subtasks'][number]
const MAX_PRECEDENTS = 5

interface SubtasksProperties {
  subtasks: SubtaskData[]
  createTask: (task: { parentTaskId: string; title: string }) => void
  taskId: string
  updateSubtaskTitle: (title: string, id: string) => void
}

type SubtaskActiveState = 'planning' | 'ungrounded' | 'idle'

export const Subtasks = ({
  createTask,
  subtasks,
  taskId,
  updateSubtaskTitle,
}: SubtasksProperties) => {
  const referenceTaskRefinement = useRef<
    RefineTaskMutation['refineTask'] | undefined
  >(undefined)
  const chatOverlay = useChatOverlay()
  const [activeState, setActiveState] = useState<SubtaskActiveState>('idle')
  const [selectedPrecedents, setSelectedPrecedence] = useState<string[]>([])

  useEffect(() => {
    setActiveState('idle')
  }, [setActiveState, taskId])

  const saveSubtask = useCallback(
    (data: CreateTaskInput) => {
      if (data.title.trim().length > 0) {
        createTask({ parentTaskId: taskId, title: data.title })
      }
    },
    [createTask, taskId]
  )

  const { refineTask, refineTaskPending, reset, taskRefinement } =
    useRefineTaskMutation(taskId, {
      onError: (error) => {
        if (error.message.includes(UNGROUNDED_TASK_ERROR)) {
          setActiveState('ungrounded')
          return
        }

        if (activeState === 'planning') {
          chatOverlay.hideChatOverlay()
          setActiveState('idle')
          referenceTaskRefinement.current = undefined

          logger.error(error)
          toast.error('Something went wrong!', {
            toastId: 'Something went wrong!',
          })
        }
      },
      onMutate: (variables) => {
        if (activeState === 'planning') {
          if (variables.precedentTaskIds?.length === 0) {
            setActiveState('ungrounded')
          }
        } else {
          setActiveState('planning')
        }
      },
      onSuccess: (data) => {
        if (referenceTaskRefinement.current === undefined) {
          referenceTaskRefinement.current = data.refineTask
          // TODO in future this logic should be done in backend
          const topPrecedents = data.refineTask.precedentTasks.slice(
            0,
            MAX_PRECEDENTS
          )
          setSelectedPrecedence(topPrecedents.map((task) => task.id))
        }
      },
    })

  const handleReset = useCallback(() => {
    referenceTaskRefinement.current = undefined
    reset()
    setActiveState('idle')
  }, [reset])

  const debouncedRefineTask = useMemo(
    () => debounce(refineTask, 700),
    [refineTask]
  )

  const handleUngroundedRefinement = useCallback(() => {
    refineTask({ precedentTaskIds: [] })
  }, [refineTask])

  const handleSelectPrecedent = useCallback(
    (taskId: string, selected: boolean) => {
      const newSelection = uniq(
        selected
          ? [...selectedPrecedents, taskId]
          : selectedPrecedents.filter((id) => id !== taskId)
      )
      setSelectedPrecedence(newSelection)

      if (newSelection.length === 0) {
        setActiveState('ungrounded')
        return
      }

      if (selectedPrecedents.length > 1 && selected) {
        debouncedRefineTask({ precedentTaskIds: newSelection })
      } else {
        refineTask({ precedentTaskIds: newSelection })
      }
    },
    [debouncedRefineTask, refineTask, selectedPrecedents]
  )

  return (
    <>
      {activeState === 'idle' && (
        <SubtasksContainer
          isRefiningTask={refineTaskPending}
          onRefineTask={refineTask}
          onSaveSubtask={saveSubtask}
          subtasks={subtasks}
          taskId={taskId}
          updateSubtaskTitle={updateSubtaskTitle}
        />
      )}

      {activeState === 'planning' && (
        <>
          {refineTaskPending ? (
            <SubtasksPlanningLoadingView />
          ) : (
            <SubtasksPlanning
              onRefineTask={refineTask}
              setNotPlanning={handleReset}
              taskRefinement={taskRefinement}
              initialTaskRefinement={referenceTaskRefinement.current}
              taskId={taskId}
            />
          )}
        </>
      )}

      {activeState === 'ungrounded' && (
        <SubtasksRefinementError
          onCancel={handleReset}
          refineTask={handleUngroundedRefinement}
        />
      )}

      {chatOverlay.isChatOverlayVisible && (
        <SubtasksPlanningPrecedentSelection
          hideChatOverlay={chatOverlay.hideChatOverlay}
          precedentTasks={referenceTaskRefinement.current?.precedentTasks ?? []}
          onSelectPrecedent={handleSelectPrecedent}
          selectedPrecedents={selectedPrecedents}
        />
      )}
    </>
  )
}

Subtasks.displayName = 'Subtasks'
