import { useCallback, FC } from 'react'
import { createPortal } from 'react-dom'

import { ChatViewEventsThread } from '@/features/task/components/chat/chat-view/ChatViewEventsThread'
import { ChatMessageInputWithNewMessages } from '@/features/task/components/chat/ChatMessageInputWithNewMessages'
import { useChat } from '@/features/task/components/chat/useChat'
import { FileMetadata } from '@/gql/generated/graphql'

interface ChatViewProperties {
  taskId: string
  isRenderedInStackCard: boolean
  taskFooterElement: HTMLDivElement | null | undefined
}

export const ChatView: FC<ChatViewProperties> = ({
  isRenderedInStackCard,
  taskFooterElement,
  taskId,
}) => {
  const {
    createNewMessage,
    handleScrollToNewMessage,
    hasNewReceivedMessage,
    isCreatingNewMessage,
    newMessageCallback,
    previousMessageCallback,
    thread,
    threadEvents,
  } = useChat<HTMLDivElement>(taskId)

  const handleSendMessage = useCallback(
    (data: {
      message: string
      fileIds: string[]
      fileMetaData: FileMetadata[]
    }) => {
      // consolidate for different string format from react mention
      const formatMentions = (text: string) => {
        const mentionRegex = /@\[(.+?)\]\((.+?)\)/g

        return text.replaceAll(mentionRegex, '<@U$2> ')
      }

      if (thread?.thread.id) {
        createNewMessage({
          body: {
            fileIds: data.fileIds,
            text: formatMentions(data.message),
          },
          fileMetaData: data.fileMetaData,
          threadId: thread.thread.id,
        })
      }
    },
    [createNewMessage, thread]
  )

  const handleScrollToNewMessageSync = useCallback(() => {
    handleScrollToNewMessage()
  }, [handleScrollToNewMessage])

  return (
    <div className="flex flex-1 flex-col justify-end overflow-hidden">
      <div
        className="flex flex-col gap-6 overflow-y-auto pb-6"
        data-testid="chat-view"
      >
        <ChatViewEventsThread
          newMessageCallback={newMessageCallback}
          previousMessageCallback={previousMessageCallback}
          threadEvents={threadEvents}
          taskId={taskId}
        />
      </div>

      {isRenderedInStackCard && taskFooterElement ? (
        createPortal(
          <ChatMessageInputWithNewMessages
            onNewMessagesButtonClick={handleScrollToNewMessageSync}
            onSendMessage={handleSendMessage}
            disabled={isCreatingNewMessage}
            hasNewReceivedMessage={hasNewReceivedMessage}
          />,
          taskFooterElement
        )
      ) : (
        <ChatMessageInputWithNewMessages
          onNewMessagesButtonClick={handleScrollToNewMessageSync}
          onSendMessage={handleSendMessage}
          disabled={isCreatingNewMessage}
          hasNewReceivedMessage={hasNewReceivedMessage}
        />
      )}
    </div>
  )
}

ChatView.displayName = 'ChatView'
