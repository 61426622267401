import { useCallback } from 'react'

import { AlertDialog } from '@/components/ui/alertDialog/AlertDialog'
import { useDeleteThreadEvent } from '@/features/task/components/chat/useDeleteThreadEvent'

interface DeleteThreadDialogProperties {
  isDialogOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  taskId: string
  threadEventId: string
}

export const DeleteThreadDialog = ({
  isDialogOpen,
  onOpenChange,
  taskId,
  threadEventId,
}: DeleteThreadDialogProperties) => {
  const { mutate } = useDeleteThreadEvent({ taskId })

  const handleDelete = useCallback(() => {
    mutate(threadEventId)
  }, [mutate, threadEventId])

  return (
    <AlertDialog
      className="bg-paper-high"
      isDialogOpen={isDialogOpen}
      onActionClick={handleDelete}
      onOpenChange={onOpenChange}
      title="Are you sure you want to delete this post?"
      description="Deleted posts can not be recovered."
      cancelText="Cancel"
      actionText="Delete"
    />
  )
}

DeleteThreadDialog.displayName = 'DeleteThreadDialog'
